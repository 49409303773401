.v-input {
  flex: 1;
  width: 100%;
}

.v-text {
  border: 0;
  outline: 0;
  width: 100%;
  margin-bottom: 1.8rem;
  background: #fefefe;
  transition: var(--transition);
  color: black;
  font-weight: 700;
  font-size: 1.6rem;
}

input.v-text, textarea.v-text {
  padding-left: 1rem;
  padding-right: 3rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.2;
  margin-bottom: 0;
  width: 100%;
}

input.v-text, textarea.v-text, .rw-widget .rw-widget-input, .v-select .v-select-caption {
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  box-shadow: 0 0 0 0;
  background: white;
}
.v-select-small-no-border>.v-select-caption {
  padding: 0.6rem 1.25rem;
  top: 1px; /* compensation for box-shadow */
  position: relative;
}
input.v-text, .rw-widget-input:not(.v-button-small+div>.rw-widget-input):not(.rw-widget-picker) {
  line-height: 4rem;
}
input.v-text, .rw-widget .rw-widget-input.rw-widget-picker, .v-select .v-select-caption {
  min-height: 34px;
  max-height: 34px;
}

textarea.v-text {
  font-family: 'Arial', 'sans-serif';
  font-weight: normal;
}

.v-text:focus {
  background: #f7f7f7;
  border-bottom: 1px solid #aaaaaa;
}

.v-text.v-api-key-value {
  margin-bottom: 0.9rem;
  border: none;
  line-height: 3rem;
}

.v-text.v-email-override {
  font-size: 1.5rem;
  line-height: 3rem;
  margin-bottom: 0;
  margin-right: 0.5rem;
  border: none;
  padding-left: 0.5rem;
  font-weight: 500;
}

.v-text-label {
  display: block;
  color: #999999;
  font-size: 1.15rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 0.5rem;
  padding-bottom: 0.25rem;
}

.v-input-row {
  width: 100%;
  justify-content: flex-start;
}

.v-password {
  /*padding-right: 4rem;*/
}

.v-passowrd-icon {
  color: #999999;
  margin-top: -1rem;
  margin-left: -3rem;
  padding: 0.25rem;
  transition: var(--transition);
  cursor: pointer;
  top: 7px;
  position: relative;
}

.v-passowrd-icon:hover {
  color: #000000;
}

.v-text-black,
.v-text-black:focus {
  color: #ffffff;
  background: #000000;
  box-shadow: inset 0 0 0 50px #000;
}

.v-text-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.rw-widget-input.rw-state-disabled,
.rw-state-disabled .rw-widget-input {
  opacity: .5;
  background-color: #fff;
}