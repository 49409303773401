@import url(https://fonts.googleapis.com/css?family=Open+Sans);
.v-analytics-card {
  padding-top: 2rem;
  margin-right: 2rem;
  text-align: center;
}

.v-analytics-card .v-card-body {
  padding: 1rem;
}

.v-analytics-label {
  color: #777777;
  background: #ffffff;
  padding: 0 0.75rem;
}

.v-analytics-value {
  font-size: 3.5rem;
  font-weight: 600;
  margin: 1rem 0;
}

.v-analytics-lines {
  margin-bottom: -1rem;
  height: 0.4rem;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.v-empty-state-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
}

.v-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 400px;
  padding: 2rem;
}

.v-empty-state-icon {
  margin-bottom: 1.5rem;
}

.v-empty-state-title {
  margin: 0 0 0.5rem 0;
  color: #333;
}

.v-empty-state-message {
  margin: 0;
  color: #666;
  font-size: 1rem;
}

@font-face {
  font-family: 'Avenir Next';
  src: url(/static/media/AvenirNext-Bold-01.39d7707a.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url(/static/media/AvenirNext-DemiBold-03.cdeaa6c9.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url(/static/media/AvenirNext-Medium-06.588a49f7.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url(/static/media/AvenirNext-Regular-08.872961ef.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

html,
body {
  font-family: 'Avenir Next', 'Open Sans', 'Arial', 'sans-serif';
  font-size: 10px;
  font-weight: 500;
  color: #000000;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 1.4rem;
  font-weight: 500;
}

* {
  box-sizing: border-box;
}

hr {
  margin: 3rem 0;
  padding: 0;
}

button,
button:focus {
  outline: 0;
  border: 0;
  background: white;
}

button {
  padding: 0;
}

a {
  color: #666666;
  text-decoration: none;
}
a:hover,
a:focus {
  text-decoration: underline;
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

::-webkit-scrollbar-thumb {
  background: #cccfdc;
  border-radius: 10rem;
  border: 2px solid #f7f8fa;
}

::-webkit-scrollbar-track {
  background: #f7f8fa;
  border-radius: 0;
}

:-ms-input-placeholder {
  color: #dddddd;
  opacity: 1;
}

::placeholder {
  color: #dddddd;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #dddddd;
}

::-ms-input-placeholder {
  color: #dddddd;
}

.v-dark-scroller::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 10rem;
  border: 1px solid #000000;
}

.v-dark-scroller::-webkit-scrollbar-track {
  background: #000000;
  border-radius: 0;
}

.v-testmode .v-dark-scroller::-webkit-scrollbar-thumb {
  background: #eeeeee;
  border: 1px solid #000000;
}

.v-testmode .v-dark-scroller::-webkit-scrollbar-track {
  background: #00679c;
  border-radius: 0;
}

/*
:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
    background: #ffffff;
    -webkit-text-fill-color: #000000 !important;
    -webkit-box-shadow: inset 0 0 0 50px #ffffff;
}
*/

.vanlo-app {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.v-icon-10 {
  height: 1rem;
}

.v-icon-12 {
  height: 1.2rem;
}

.v-icon-14 {
  height: 1.4rem;
}

.v-icon-16 {
  height: 1.6rem;
}

.v-icon-18 {
  height: 1.8rem;
}

.v-icon-20 {
  height: 2rem;
}

.v-icon-22 {
  height: 2.2rem;
}

.v-icon-24 {
  height: 2.4rem;
}

.v-icon-copy-hash {
  height: 1.7rem;
  opacity: 0.4;
  transition: all 0.25s ease;
  cursor: pointer;
}

.v-icon-copy-hash:hover {
  opacity: 1;
}

:root {
  --sidebar-width: 26rem;
  --sidebar-icon-spacing: 0.8rem;
  --sidebar-items-height: 3.0rem;
  --sidebar-item-padding: 2rem;
  --separator: 1px solid rgba(255, 255, 255, 0.1);
  --transition: all 0.25s ease;
  --transition-fast: all 0.15s ease;
}

.v-fullscreen {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  font-size: 1.5rem;
}

.v-link {
  color: #000000;
  border-bottom: 1px solid #cccccc;
  text-decoration: none;
  transition: var(--transition);
}

.v-link:hover {
  opacity: 1;
  border-bottom: 1px solid #777777;
}

.v-link-no-line,
.v-link-no-line:hover {
  border-bottom: none;
}

.v-page {
  height: 100vh;
  overflow-x: hidden;
  padding: 3rem 4rem;
  background: #f9f9f9;
}

.v-title {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 1.5rem;
  text-transform: capitalize;
}

.v-subtitle {
  font-weight: 500;
  font-size: 1.6rem;
  padding: 1rem 0;
  margin-top: 1.75rem;
  line-height: 1rem;
  color: #888888;
}

.v-center {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  text-align: center;
}

.v-center-column {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
}

.v-center-left, .v-center-left-right {
  display: flex;
  text-align: left;
  align-items: center;
  width: 100%;
}

.v-center-left {
  justify-content: flex-start !important;
}

.v-center-left-right {
  justify-content: space-between !important;
}

.v-center-right {
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.v-center-column.v-center-left {
  align-items: flex-start;
  justify-content: center;
}

.v-left {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
}

.v-right {
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
}

.v-separator {
  border-bottom: var(--separator);
}

.v-ellipsed {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}

.v-headline-label-text {
  font-size: 1.3rem;
  border-radius: 5rem;
  background: #f7f7f7;
  padding: 1rem 1.5rem;
  margin-left: -1.5rem;
}

.v-pointer {
  cursor: pointer;
}

.v-fixed {
  position: fixed !important;
}

.v-text-red {
  color: #ff3311;
}

.v-link {
  cursor: pointer;
}

.v-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-hint {
  font-size: smaller;
  font-weight: lighter;
}

.v-style-guide {
  padding: 2rem;
}

.v-style-guide-sample {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #eeeeee;
}

.v-style-guide-label {
  color: #777777;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  display: block;
}

.v-button {
  padding: 0 2.8rem;
  box-shadow: 0 0 0 2px #000;
  height: 3.2rem;
  border-radius: 2px;
  transition: var(--transition-fast);
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff;
  background: #000000;
  display: inline-flex;
}

.v-button:focus,
.v-button:hover {
  color: #000000;
  background: #ffffff;
  box-shadow: 0 0 0 2px #555;
}
.v-button:focus a,
.v-button:hover a {
  color: #ffffff;
}

.v-button-white {
  color: #555555;
  background: #ffffff;
  box-shadow: 0 0 0 1px #aaaaaa;
}
.v-button-white a {
  color: #555555;
}
.v-button-white:focus,
.v-button-white:hover {
  color: #ffffff;
  background: #000000;
  box-shadow: 0 0 0 2px #000000;
}

.v-button-round {
  border-radius: 10rem;
  padding: 0.5rem 2.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  box-shadow: 0 0 0 1px #cccccc;
}

.v-button-round:hover {
  box-shadow: 0 0 0 1px #000000;
}

.v-button-small {
  font-size: 1rem;
  font-weight: 600;
  padding: 0 1rem;
  height: 2.25rem;
  display: inline-flex;
  align-items: center;
  box-shadow: 0 0 0 1px #555;
  margin: 2px;
}

.v-button-small:hover {
  box-shadow: 0 0 0 1px #555;
}
.v-button-white.v-button-small:hover {
  box-shadow: 0 0 0 1px #000;
}

.v-icon-button {
  color: #555555;
  width: 2.8rem;
  height: 2.8rem;
  padding: 0.4rem;
  margin-right: 0.15rem;
  border-radius: 3px;
  transition: var(--transition);
  cursor: pointer;
  /*border: 1px solid transparent;*/
}

.v-icon-button.disabled {
  cursor: auto;
  cursor: initial;
  opacity: 0.4;
}

.v-icon-button:hover {
  color: #000000;
  background: #ffffff;
  border: 1px solid #eeeeee;
}

.v-icon-disabled {
  color: #555555;
  background: #ffffff;
  opacity: 0.2;
}

.v-button-disabled,
.v-button-disabled:hover {
  color: #ffffff;
  background: #bbbbbb;
  box-shadow: 0 0 0 2px #bbbbbb;
  cursor: not-allowed;
  opacity: 0.5;
}

.v-button-round.v-button-disabled,
.v-button-round:disabled {
  color: #999999;
  background: #ffffff;
  box-shadow: 0 0 0 1px #aaaaaa;
}

.v-stripe-button {
  background: rgb(103, 114, 229);
  box-shadow: 0 0 3px #6772e5;
}

.v-plaid-button {
  flex-wrap: wrap
}

.v-title-button {
  /*margin-right: auto;*/
  margin-left: 2rem;
}

.v-title-button .v-button {
  padding: 0 1.8rem;
}

.v-username {
  margin-left: auto;
}

.v-input {
  flex: 1 1;
  width: 100%;
}

.v-text {
  border: 0;
  outline: 0;
  width: 100%;
  margin-bottom: 1.8rem;
  background: #fefefe;
  transition: var(--transition);
  color: black;
  font-weight: 700;
  font-size: 1.6rem;
}

input.v-text, textarea.v-text {
  padding-left: 1rem;
  padding-right: 3rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.2;
  margin-bottom: 0;
  width: 100%;
}

input.v-text, textarea.v-text, .rw-widget .rw-widget-input, .v-select .v-select-caption {
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  box-shadow: 0 0 0 0;
  background: white;
}
.v-select-small-no-border>.v-select-caption {
  padding: 0.6rem 1.25rem;
  top: 1px; /* compensation for box-shadow */
  position: relative;
}
input.v-text, .rw-widget-input:not(.v-button-small+div>.rw-widget-input):not(.rw-widget-picker) {
  line-height: 4rem;
}
input.v-text, .rw-widget .rw-widget-input.rw-widget-picker, .v-select .v-select-caption {
  min-height: 34px;
  max-height: 34px;
}

textarea.v-text {
  font-family: 'Arial', 'sans-serif';
  font-weight: normal;
}

.v-text:focus {
  background: #f7f7f7;
  border-bottom: 1px solid #aaaaaa;
}

.v-text.v-api-key-value {
  margin-bottom: 0.9rem;
  border: none;
  line-height: 3rem;
}

.v-text.v-email-override {
  font-size: 1.5rem;
  line-height: 3rem;
  margin-bottom: 0;
  margin-right: 0.5rem;
  border: none;
  padding-left: 0.5rem;
  font-weight: 500;
}

.v-text-label {
  display: block;
  color: #999999;
  font-size: 1.15rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 0.5rem;
  padding-bottom: 0.25rem;
}

.v-input-row {
  width: 100%;
  justify-content: flex-start;
}

.v-password {
  /*padding-right: 4rem;*/
}

.v-passowrd-icon {
  color: #999999;
  margin-top: -1rem;
  margin-left: -3rem;
  padding: 0.25rem;
  transition: var(--transition);
  cursor: pointer;
  top: 7px;
  position: relative;
}

.v-passowrd-icon:hover {
  color: #000000;
}

.v-text-black,
.v-text-black:focus {
  color: #ffffff;
  background: #000000;
  box-shadow: inset 0 0 0 50px #000;
}

.v-text-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.rw-widget-input.rw-state-disabled,
.rw-state-disabled .rw-widget-input {
  opacity: .5;
  background-color: #fff;
}
.v-checkbox {
  padding: 0.75rem 0;
  margin-right: 0.75rem;
}

.v-checkbox-label {
  margin: 0 0.75rem;
}

.v-checkbox-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #000000;
}

.v-checkbox-box {
  width: 1.8rem;
  height: 1.8rem;
  flex-shrink: 0;
  border-radius: 3px;
  background: #ffffff;
  display: inline-block;
  border: 1px solid #333333;
}

.v-checkbox-tick {
  opacity: 1;
  position: absolute;
  width: 1.2rem;
  height: 1.3rem;
  margin-left: 0.3rem;
}

.v-checked {
  opacity: 0;
}

.v-checkbox-disabled {
  color: #999999;
  cursor: not-allowed;
}

.v-checkbox-disabled .v-checkbox-box {
  border: 1px solid #999999;
}

.v-table-checkbox {
  max-width: 5rem;
}

.v-select {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  /*height: 3rem;*/
}

.v-select-caption {
  justify-content: space-between;
  padding: 1rem 1.25rem;
  box-shadow: 0 0 1px 1px #999999;
  border-radius: 4px;
  background: #fafafa;
  transition: var(--transition);
}

.v-select.v-select-reverse > .v-select-caption {
  background: #000;
}

.v-select.v-select-reverse.v-open .v-select-caption {
  color: #000000;
  background: #ffffff;
  box-shadow: 0 0 1px 1px #000000;
}

.v-select-caption > .v-arrow-icon {
  opacity: 0.5;
  transform: rotate(90deg);
}

.v-select-caption:hover,
.v-select.v-open .v-select-caption {
  color: #ffffff;
  background: #000000;
  box-shadow: 0 0 1px 1px #000000;
}

.v-select.v-open > .v-select-caption > .v-arrow-icon {
  opacity: 1;
  transform: rotate(-90deg);
}

.v-select-list-container {
  position: relative;
  margin-top: 2px;
}

.v-select-list {
  position: absolute;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  /* margin-top: calc(6rem); */
  box-shadow: 0 0 1px 1px #999999;
  border-radius: 4px;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  /* transition: var(--transition-fast); */
}

.v-select-list.v-select-popup {
  bottom: 32px;
}

.v-select.v-open .v-select-list {
  visibility: visible;
  opacity: 1;
}

.v-select-option {
  padding: 0 1rem;
  line-height: 40px;
  display: flex;
  align-items: center;
  background: #ffffff;
  white-space: nowrap;
  transition: var(--transition);
}

.v-select-option:hover {
  background: #f3f3f3;
}

.v-select .v-select-option.v-selected {
  color: #000000;
  background: #dddddd;
}

.v-select-disabled,
.v-select-disabled:hover,
.v-select-disabled > .v-arrow-icon,
.v-select-disabled .v-select-caption {
  color: #999999;
  cursor: not-allowed;
}

.v-select-underlined > .v-select-caption {
  box-shadow: none;
  border: 0;
  border-bottom: 2px solid #aaaaaa;
  border-radius: 0;
  margin-bottom: -0.2rem;
  background: #ffffff;
}

.v-select-underlined:hover > .v-select-caption,
.v-select-underlined.v-open .v-select-caption {
  color: inherit;
  box-shadow: none;
  background: #f3f3f3;
  border-radius: 4px 4px 0 0;
  border-bottom: 2px solid #333333;
}

.v-select-disabled .v-select-caption,
.v-select-disabled:hover .v-select-caption {
  background: #ffffff !important;
  border: 2px solid #f3f3f3 !important;
}

.v-select-wrapper {
  display: flex;
}
.v-select-wrapper.v-select-inline {
  position: relative;
}

.v-select-tiny-no-border > .v-select-caption {
  box-shadow: 0 0 0 1px #eeeeee;
  padding: 0.25rem 1.25rem;
  flex: 1 1;
  border-radius: 0;
}

.v-select-tiny-no-border .v-select-option {
  line-height: 2em;
  color: #000000;
}

.v-select-small-no-border > .v-select-caption {
  box-shadow: 0 0 0 1px #eeeeee;
  padding: 0.75rem 1.25rem;
  flex: 1 1;
}

.v-select-small-no-border:hover > .v-select-caption {
  box-shadow: 0 0 0 1px #eeeeee;
}

.v-select-small-no-border > .v-select-list-container {
  margin-top: 2px;
  width: 100%;
  flex: 1 1;
}

.v-select-inline > .v-select-caption {
  width: 100%;
}

.v-select-inline {
  align-items: center;
}

.v-text-label-left {
  margin-right: 0.75rem;
  font-size: 1.2rem;
  /* font-weight: 600; */
  color: #777777;
  text-align: left;
  flex-shrink: 0;
}
.v-select-wrapper.v-select-inline .v-text-label-left {
  position: absolute;
  top: -1.5rem;
  z-index: 1;
}

.v-select-up .v-select-list {
  /* bottom: calc(3.5rem + 1px); */
  bottom: 3.5rem;
}
.v-add-payment-method {
  margin-top: 0px;
  margin-bottom: 0px;
}

.v-card {
  box-shadow: 0 0 0 2px #e2e2e2;
  border-radius: 4px;
  background: #ffffff;
  width: 100%;
  margin-bottom: 3rem;
}

.v-card-title {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0 2rem;
  color: #999999;
  background: #e2e2e2;
  justify-content: flex-start;
  text-transform: uppercase;
  line-height: 3.5;
}

.v-card-body {
  /* display: flex;
    flex-direction: column; */
  flex: 1 1;
  padding: 2rem;
}

.v-dark-card {
  background: #000000;
  box-shadow: 0 0 0 1px #e2e2e2;
}

.v-dark-card:-ms-input-placeholder {
  color: #444444;
  opacity: 1;
}

.v-dark-card::placeholder {
  color: #444444;
  opacity: 1;
}

.v-dark-card:-ms-input-placeholder {
  color: #444444;
}

.v-dark-card::-ms-input-placeholder {
  color: #444444;
}

.v-dark-card .v-passowrd-icon:hover {
  color: #ffffff !important;
}

.v-sidebar {
  display: flex;
  flex-direction: column;
  width: var(--sidebar-width);
  height: 100vh;
  color: #888888;
  background: #000000;
  flex-shrink: 0;
  overflow: hidden;
}

.v-sidebar.v-testmode {
  background: #00679c;
  color: #ffffff;
}

.v-sidebar-menu {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1;
}

.v-sidebar .v-selected {
  color: #ffffff;
}

.v-sidebar-item {
  padding: 0 var(--sidebar-item-padding);
  line-height: var(--sidebar-items-height);
  cursor: pointer;
  transition: color 0.25s ease;
  display: block;
  color: #888888;
}

.v-testmode .v-sidebar-item {
  color: #ffffff;
}

.v-sidebar-items:hover .v-sidebar-items-label {
  color: #ffffff;
}

.v-sidebar-item:hover {
  color: #ffffff;
  background: #111111;
  /* font-weight: 600; */
}

.v-sidebar-list .v-sidebar-item {
  padding-left: calc(var(--sidebar-icon-spacing) + 2 * var(--sidebar-item-padding));
}

.v-sidebar-list {
  height: 0;
  overflow: hidden;
  transition: var(--transition);
  border-bottom: var(--separator);
}

.v-sidebar-items-label {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  padding-left: var(--sidebar-item-padding);
  padding-right: calc((0.75 + 0.25) * var(--sidebar-item-padding));
  line-height: var(--sidebar-items-height);
  transition: var(--transition);
  align-items: center;
  cursor: pointer;
}

.v-sidebar-items-label:hover {
  color: #ffffff;
  background: #111111;
  /* font-weight: 600; */
}

.v-sidebar-label-title {
  display: flex;
  align-items: center;
}

.v-item-icon {
  display: flex;
  align-items: center;
  margin-right: var(--sidebar-icon-spacing);
}

.v-sidebar-label-title .v-item-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.v-sidebar-items-label .v-arrow-icon {
  transition: var(--transition);
  transform: rotate(0deg);
}

.v-sidebar-items-label.v-open .v-arrow-icon {
  transform: rotate(90deg);
}

.v-sidebar-header {
  color: #ffffff;
  font-size: 2.4rem;
  line-height: 8rem;
  font-weight: bold;
  border-bottom: var(--separator);
  padding-left: var(--sidebar-item-padding);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #333333;
}

.v-testmode .v-sidebar-header {
  border-bottom: 1px solid #2475a6;
}

.v-sidebar-header > a {
  color: #ffffff;
}

.v-sidebar-menu-icon {
  margin-top: -2px;
  transition: var(--transition);
  color: #eeeeee;
  cursor: pointer;
}

.v-sidebar-menu-icon:hover {
  color: #ffffff;
}

.v-footer {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  font-size: 1.3rem;
  border-top: 1px solid #0a0a0a;
}

.v-testmode .v-footer {
  border-top: 1px solid #2476a6;
}

.v-footer-links {
  padding: 2rem 0.25rem;
}

.v-footer-terms,
.v-footer-privacy {
  flex: 1 1;
  color: #555555;
  white-space: nowrap;
  border-bottom: 1px solid #222222;
}

.v-testmode .v-footer-terms,
.v-testmode .v-footer-privacy {
  color: #ffffff;
  border-bottom: 1px solid #2476a6;
}

.v-footer-terms:hover,
.v-footer-privacy:hover {
  border-bottom: 1px solid #555555;
}

.v-footer-terms {
  margin-right: 2.25rem;
}

.v-footer-copyright {
  color: #555555;
  white-space: nowrap;
}

.v-testmode .v-footer-copyright {
  color: #ffffff;
}

:root {
  color-scheme: light dark; /* both supported */
}

.v-body {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (prefers-color-scheme: dark) {
  .v-auto-darkmode .v-body,
  .v-auto-darkmode .v-modal {
    filter: invert(1);
  }
  .v-auto-darkmode .v-modal {
    box-shadow: 0 0 0 2px #aaa;
  }
}
.v-darkmode .v-body,
.v-darkmode .v-modal {
  filter: invert(1);
}
.v-darkmode .v-modal {
  box-shadow: 0 0 0 2px #aaa;
}

.v-modal-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.85);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: none;
}

.v-modal-visible {
  opacity: 1;
  visibility: visible;
  transition: var(--transition);
}

.v-modal {
  border-radius: 4px;
  min-width: 30rem;
  overflow: hidden;
}

.v-modal-title {
  color: #000000;
  background: #f7f7f7;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 1.5rem 2rem;
  text-transform: uppercase;
  border-bottom: 1px solid #bbbbbb;
  justify-content: space-between;
  cursor: default;
  display: flex;
  align-items: center;
}

.v-modal-body {
  max-height: 80vh;
  background: #ffffff;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  overflow: auto;
  width: 100%;
  padding: 2rem 2rem;
}
.v-modal-body .v-select-list {
  position: fixed;
  width: auto;
}

.v-modal-icon {
  opacity: 0.8;
  cursor: pointer;
  transition: var(--transition);
}

.v-modal-icon:hover {
  opacity: 1;
}

.v-modal-footer {
  padding-top: 1rem;
}

.v-body-content {
  max-height: 60vh;
  overflow: auto;
  width: 100%;
  padding: 2rem 3rem;
}

.v-error-message {
  color: #ff3300;
  font-weight: 500;
}

.v-error-message .v-button {
  color: #ffffff;
  background: #f8665b;
  box-shadow: none;
  padding: 1.75rem 0;
}

.v-modal-heading {
  font-size: 2rem;
  color: #777;
  margin: 0.6em 0;
}

.v-table {
  display: block;
}

.v-table-table {
  font-size: 1.4rem;
  font-weight: 600;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.v-table-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0 1px #dddddd;
  border-radius: 2px;
  overflow-x: hidden;
  overflow-y: auto;
}

.v-table-auto-height > .v-table-content {
  overflow: unset;
}

.v-table-tr {
  background: #ffffff;
  display: flex;
  border-bottom: var(--separator);
  cursor: default;
  border: 1px solid transparent;
}

.v-table-tr-disabled .v-table-td:first-child {
  opacity: 0.5;
}

.v-table-th .v-checkbox,
.v-table-td .v-checkbox {
  padding: 0 0;
}

.v-table-td.v-no-pad {
  display: block;
  padding: 0;
}

.v-table-th,
.v-table-td {
  flex: 1 1;
  display: flex;
  align-items: center;
  border: var(--separator);
  padding: 0.75rem 1rem;
  overflow: hidden;
  height: 3.6rem;
  position: relative;
}

.v-table-tr:nth-child(odd) {
  background-color: #fcfcfc;
}

.v-table-tr:hover {
  background: #f7f7f7;
  border: 1px solid #dddddd;
}

.v-table-th {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0 1rem;
  color: #888888;
  background: #ebebeb;
  justify-content: flex-start;
  text-transform: uppercase;
}

.v-table-tr-empty {
  height: 15rem;
  color: #bbbbbb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-icon-status {
  white-space: normal !important;
  font-size: 1.2rem !important;
  display: flex;
  text-align: center;
}

.v-grids {
  display: flex;
  width: 100%;
  flex: 1 1;
}

.v-grid-column {
  flex-direction: column;
}

.v-grid-1 {
  display: flex;
  flex: 1 1;
}

.v-grid-2 {
  display: flex;
  flex: 2 1;
}

.v-grid-3 {
  display: flex;
  flex: 3 1;
}

.v-grid-4 {
  display: flex;
  flex: 4 1;
}

.v-grid-5 {
  display: flex;
  flex: 5 1;
}

.v-grid-6 {
  display: flex;
  flex: 6 1;
}

.v-no-grid {
  display: unset;
  flex: 1 1;
}

.v-calendar,
.react-calendar {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 0 1px #dddddd;
  border: 0;
}

.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--weekend {
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0.75rem 0;
  border-radius: 1px;
  cursor: pointer;
}

.react-calendar__navigation {
  border-bottom: 1px solid #dddddd;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #999999;
}

.react-calendar__month-view__weekdays {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem 0;
  text-transform: uppercase;
}

.react-calendar__century-view__decades__decade,
.react-calendar__decade-view__years__year,
.react-calendar__year-view__months__month {
  padding: 1.75rem 0.75rem;
  border-radius: 1px;
  cursor: pointer;
}

.react-calendar__year-view__months__month abbr:hover {
  padding: 0.25rem;
}

.react-calendar__month-view__days__day:hover,
.react-calendar__year-view__months__month:hover {
  background: #e2e2e2;
}

.react-calendar__month-view {
  /* padding: 1rem 1rem; */
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__navigation__label,
.react-calendar__navigation__arrow {
  padding: 1rem 1.5rem;
  font-weight: 700;
  cursor: pointer;
  transition: var(--transition);
}

.react-calendar__navigation__label {
  padding-left: 0;
  padding-right: 0;
}

.react-calendar__navigation__arrow {
  font-size: 1.6rem;
}

.react-calendar__navigation__label:hover,
.react-calendar__navigation__arrow:hover {
  background: #e2e2e2;
}

.react-calendar__tile--active:focus,
.react-calendar__tile--active:hover,
.react-calendar__tile--active,
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeBothEnds,
.react-calendar__tile--hasActive:hover {
  color: #ffffff;
  border-radius: 1px;
  background: #111111;
  box-shadow: inset 0 0 0 1px #000000;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #111111;
}

.v-range-date:hover {
  color: #ffffff;
  background: #111111;
}

.v-range-date {
  display: inline-block;
  padding: 0.4rem 2rem;
  border: 1px solid #222222;
  margin: 0.4rem;
  border-radius: 5rem;
  cursor: pointer;
  transition: all 0.25s ease;
  text-align: center;
  white-space: nowrap;
}

.v-calendar-container {
  display: flex;
  width: 50rem;
}

.v-calendar-dates {
  display: flex;
  flex-direction: column;
}

.v-calendar-table {
  padding-right: 2rem;
}

.v-calendar-ranges {
  display: flex;
  align-items: center;
}

.v-calendar-range {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  background: #f7f7f7;
  border-radius: 2px;
  border: none;
  outline: 0;
  font-size: 100%;
  width: 10rem;
}

.v-calendar-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.v-awaiting-shipments {
  padding: 3rem 4rem;
}

.v-search-box:focus,
.v-search-box {
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  padding-left: 1rem;
  padding-right: 3rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.2;
  margin-bottom: 0;
  width: 100%;
}
.v-search-box:-ms-input-placeholder {
  color: #ababab;
}
.v-search-box::placeholder {
  color: #ababab;
}

.v-search-box:focus {
  background: #ffffff;
}

.v-clear-search-box-icon {
  width: 3rem;
  box-sizing: border-box;
  padding: 0.9rem;
  margin-left: -3.25rem;
  color: #777777;
  z-index: 0;
  transition: all 0.25s ease;
}

.v-clear-search-box-icon:hover {
  color: #000000;
}

.v-table-data-row {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.v-table-data-row-label {
  font-size: 1.4rem;
  display: inline-block;
  width: 18rem;
  color: #666666;
}

.v-table-data-row-info {
  font-size: 1.4rem;
  font-weight: 500;
  background: #fcfcfc;
  padding: 0.5rem 1rem;
  display: inline-block;
  border: 1px solid #f7f7f7;
  border-radius: 4px;
  word-break: break-all;
  max-width: calc(100% - 19rem);
}

.v-menu {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
}

.v-menu-separator {
  height: 1px;
  background: #dddddd;
}

.v-menu-caption {
  justify-content: space-between;
  padding: 1rem 1.25rem;
  box-shadow: 0 0 1px 1px #999999;
  border-radius: 4px;
  margin-top: 0.75rem;
  transition: var(--transition);
}

.v-menu-caption > .v-arrow-icon {
  color: #999999;
  transform: rotate(90deg);
}

.v-menu-caption:hover,
.v-menu.v-open .v-menu-caption {
  color: #ffffff;
  background: #000000;
  box-shadow: 0 0 1px 1px #000000;
}

.v-menu.v-open > .v-menu-caption > .v-arrow-icon {
  color: inherit;
  transform: rotate(-90deg);
}

.v-menu-list-container {
  position: relative;
  margin-top: 2px;
}

.v-menu-list {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(5 * 4rem);
  /* margin-top: calc(6rem); */
  box-shadow: 0 0 7px 0px #dddddd;
  border: 1px solid #dddddd;
  border-radius: 4px;
  z-index: 1;
  display: none;
}

.v-menu.v-open .v-menu-list {
  display: flex;
  flex-direction: column;
}

.v-menu-option {
  padding: 0 2rem;
  line-height: 4rem;
  display: flex;
  align-items: center;
  background: #ffffff;
  transition: var(--transition);
  cursor: pointer;
  white-space: nowrap;
  padding-right: 3rem;
}

.v-menu-option:hover {
  background: #f3f3f3;
}

.v-menu-option.v-menued {
  color: #000000;
  background: #dddddd;
}

.v-menu-disabled,
.v-menu-disabled:hover,
.v-menu-disabled > .v-arrow-icon {
  color: #ffffff;
  background: #cccccc;
  box-shadow: 0 0 0 2px #cccccc;
  cursor: not-allowed;
}

.v-menu-inside-table {
  overflow: visible;
}

.v-menu-right {
  right: 0;
}

.v-toggle {
  display: block;
  border-radius: 10rem;
  width: 4.5rem;
  height: 2.4rem;
  position: relative;
  background: rgb(0, 0, 0);
  transition: all 0.25s ease;
  cursor: pointer;
}

.v-toggle.v-toggle-off {
  background: #999999;
}

.v-toggle-circle {
  display: block;
  border-radius: 10rem;
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  background: white;
  margin: 0.3rem;
  margin-left: 2.4rem;
  transition: all 0.25s ease;
}

.v-toggle.v-toggle-off .v-toggle-circle {
  margin-left: 0.3rem;
}

/* .v-toggle.toggle-off.disabled, */
.v-toggle.v-disabled {
  background: #bbbbbb;
  box-shadow: 0 0 1px 1px #999999;
}

.v-hr {
  height: 1px;
  margin: 0.5rem 0;
  background: #e5e5e5;
  margin-top: 1rem;
  width: 100%;
}

.v-hr-2 {
  margin: 2rem 0;
}

.v-hr-2-5 {
  margin: 2.5rem 0;
}

.v-hr-3 {
  margin: 3rem 0;
}

.v-hr-4 {
  margin: 4rem 0;
}

pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}

.v-json-string {
  color: #008000;
}

.v-json-number {
  color: #0000ff;
}

.v-json-boolean {
  color: #b22222;
}

.v-json-null {
  color: #808080;
}

.v-json-key {
  color: #000000;
}

.v-current-balance {
  flex: 1 1;
  padding: 2rem 0;
  text-align: center;
}

.v-current-balance-label {
  font-size: 1.4rem;
  color: #777777;
  background: #ffffff;
  padding: 0 0.75rem;
}

.v-current-balance-amount {
  font-size: 3.5rem;
  font-weight: 600;
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}

.v-current-balance-amount-sign {
  font-size: 60%;
  padding: 0.4rem;
}

.v-current-balance .v-button {
  margin: 0 auto;
}

.v-current-balance-lines {
  margin-bottom: -1rem;
  height: 0.4rem;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.v-subscription {
}

.v-subscription-card {
  padding: 2rem 2rem;
}

.v-subscription-title {
  font-size: 3.2rem;
  font-weight: 600;
  padding-bottom: 1.2rem;
}

.v-subscription-subtitle {
  color: #777777;
}

.v-credit-card-container {
  width: 100%;
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 6px;
  position: relative;
  margin-bottom: 2rem;
}

.v-card-container-busy {
  background: #cccccc;
}

.v-card-removing-tag {
  background: #ee3b3b;
  color: #ffffff;
  padding: 0.4rem 1rem;
  border-radius: 4px;
  font-size: 1.2rem;
  margin: 0.25rem 0;
  display: inline-block;
}

.v-card-updating-tag {
  background: #36a736;
  color: #ffffff;
  padding: 0.4rem 1rem;
  border-radius: 4px;
  font-size: 1.2rem;
  margin: 0.25rem 0;
  display: inline-block;
}

.v-card-container-busy .v-credit-card-icon {
  opacity: 0.7;
}

.v-credit-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  color: #cccccc;
  width: 6rem;
  height: 6rem;
  margin-right: 2rem;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  box-sizing: content-box;
  border: 1px solid #e3e3e3;
  background: #fefefe;
  flex-shrink: 0;
}

.v-credit-card-info {
  flex: 1 1;
}

.v-bank-account-issuer,
.v-credit-card-info-type {
  font-size: 1.8rem;
  font-weight: 500;
}

.v-bank-account-name {
  font-size: 1.4rem;
}

.v-bank-account-last4,
.v-credit-card-info-last4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  color: #333333;
}

.v-credit-card-info-expire {
  font-size: 1.1rem;
  color: #777777;
}

.v-credit-card-info-expire > b {
  font-size: 1.3rem;
  font-weight: 500;
  color: #000000;
}

.v-credit-card-status {
  color: #ffffff;
  background: #000000;
  padding: 0.4rem 0.75rem;
  font-size: 1.1rem;
  border-radius: 3px;
  margin-right: 1rem;
}

.v-credit-card-menu {
  margin: 0.25rem;
  padding: 0.75rem;
  box-sizing: content-box;
  cursor: pointer;
  border-radius: 4px;
  color: #000000;
  transition: var(--transition);
}

.v-open > .v-credit-card-menu,
.v-credit-card-menu:hover {
  background: #eeeeee;
}

.v-credit-card-menu-items {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  margin-right: -0.25rem;
  position: absolute;
  right: 0.5rem;
  top: 1.25rem;
}

.v-credit-card-menu-container {
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
}

.v-credit-card-delete {
  padding: 0 0.75rem;
  margin-left: 0.75rem;
}

.v-credit-card-delete > .v-icon-14 {
  margin: 0;
  height: 1.6rem;
}

.v-credit-card-primary {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  color: #ffffff;
  background: #111111;
  border-radius: 4px;
}

.v-warehouse-address {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 2rem 1rem 0;
}

.v-warehouse-actions {
  position: relative;
  margin-left: auto;
  white-space: nowrap;
}

.v-warehouse-action {
  margin: 0.25rem;
  padding: 0.75rem;
  box-sizing: content-box;
  cursor: pointer;
  border-radius: 4px;
  transition: var(--transition);
  background: #fcfcfc;
  display: inline-flex;
  border: 1px solid #eeeeee;
  color: #777777;
}

.v-open > .v-warehouse-action,
.v-warehouse-action:hover {
  background: #eeeeee;
  color: #000000;
}

.v-warehouse-action-items {
  display: flex;
  justify-content: flex-end;
  margin-top: 3.2rem;
  margin-right: -0.25rem;
  position: absolute;
  right: 0.5rem;
  top: 1.25rem;
}

.v-warehouse-action-container {
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
}

.v-remove-warehouse-address {
  padding: 2rem 0;
  font-size: 1.7rem;
  font-weight: 600;
}

.v-warehouse-empty {
  padding: 2rem 0;
  font-size: 1.5rem;
  text-align: center;
  color: #cccccc;
}

.v-table-invoices .overdue .status,
.v-table-invoices .overdue .due {
  color: red;
  opacity: 0.7;
}

.v-table-invoices .paid span {
  opacity: 0.5;
}

.v-table-invoices .paid .v-table-td:last-child span {
  opacity: 1;
}

.v-invoices-total {
  padding-top: 2rem;
  text-align: center;
  margin-bottom: 0;
}

.v-invoices-total .v-card-body {
  padding: 1rem;
}

.v-invoices-total-label {
  color: #777777;
  background: #ffffff;
  padding: 0 0.75rem;
}

.v-invoices-total-paid {
  color: green;
}

.v-invoices-total-overdue {
  color: red;
}

.v-invoices-total-amount {
  font-size: 3.5rem;
  font-weight: 600;
  margin: 1rem 0;
}

.v-invoices-total-lines {
  margin-bottom: -1rem;
  height: 0.4rem;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.v-invoices-download {
  color: black;
  border: none;
  transition: none;
  margin-top: 0.5rem;
}

.v-icon-button:hover {
  background: none;
  border: none;
}

.v-pay-invoice-modal .v-input .v-text-label-left {
  margin-bottom: 1.8rem;
}

.v-pay-invoice-modal .v-text {
  line-height: 3rem;
  padding-left: 0.5rem;
}

.v-pay-invoice-modal .v-text:focus {
  border-bottom: 1px solid #e2e2e2;
  background: #fefefe;
}

.v-pagination {
  padding: 2rem;
}

.v-pagination-li {
  outline: none;
}

.v-pagination-ul {
  display: inline-flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.v-pagination-ul a,
.v-pagination-ul a:focus {
  font-size: 1.3rem;
  outline: none;
}

.v-pagination-ul a {
  color: #555555;
  margin: 0.25rem;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  transition: var(--transition);
  display: inline-block;
  border-radius: 4px;
}

.v-pagination-page a:hover {
  color: #333333;
  background: #dddddd;
}

.v-pagination-page.selected a {
  color: #ffffff;
  background: #000000;
}

.v-pagination-ul .next {
  padding: 0 1rem;
  margin: 0 1rem;
  border-left: 1px dotted #999999;
}

.v-pagination-ul .previous {
  padding: 0 1rem;
  margin: 0 1rem;
  border-right: 1px dotted #999999;
}

.v-pagination-ul .next a:hover,
.v-pagination-ul .previous a:hover {
  color: #000000;
}

.v-pagination-ul .disabled a:hover,
.v-pagination-ul .disabled a {
  color: #cccccc;
  cursor: default;
}

.v-delete-person svg {
  margin: 0;
}

.v-person-toggle {
  min-width: 7rem;
}

.v-permission-checkbox span {
  margin: auto;
}

.v-dispute-expected input {
  margin-bottom: 0;
  line-height: 3rem;
  padding: 0.5rem;
}

.v-dispute-expected input:focus,
.v-dispute-details textarea:focus {
  border-bottom: 1px solid #e2e2e2;
  background: #fefefe;
}

.v-dispute-expected {
  margin-bottom: 1.8rem;
}

.v-dispute-details textarea {
  font-family: 'Arial', 'sans-serif';
  line-height: 2rem;
  padding: 0.5rem;
  resize: none;
}

.v-dispute-details label {
  width: 10rem;
}

.v-dispute-modal p:first-child,
.v-dispute-modal h2:first-child {
  margin-top: 0;
}

.v-dispute-value {
  margin-left: 0.5rem;
}

.v-dispute-card {
  margin-bottom: 2rem;
}

.v-dispute-form {
  margin-bottom: 0;
}

.tracking-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: white;
}

.brand-preview-container {
  height: 750px;
  transform: scale(0.8);
  width: 102%; /* Fix for horizontal scrollbar appearing */
  transform-origin: top center;
}

.brand-preview {
  width: 100%;
  border-width: 0;
}

.tracking-info {
  justify-content: center;
  text-align: center;
}

.tracking-number {
  font-weight: bold;
}

.tracking-history-card .v-card-body {
  padding-bottom: 1remя;
}

.tracking-history {
  padding-left: 0;
}

.tracking-history-item {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.tracking-history-time {
  flex: 70px 0;
}

.tracking-history-name {
  font-weight: bold;
}

.v-no-margin {
  margin: 0;
}

.v-no-padding {
  padding: 0;
}

.v-width-10 {
  width: 10rem;
}

.v-width-15 {
  width: 15rem;
}

.v-width-20 {
  width: 20rem;
}

.v-width-225 {
  width: 22.5rem !important
}

.v-width-25 {
  width: 25rem;
}

.v-width-30 {
  width: 30rem;
}

.v-width-40 {
  width: 40rem;
}

.v-width-50 {
  width: 50rem;
}

.v-width-60 {
  width: 60rem;
}

.v-margin-right-3rem {
  margin-right: 3rem;
}

.v-margin-right-2rem {
  margin-right: 2rem;
}

.v-margin-right-1rem {
  margin-right: 1rem;
}

.v-margin-right-1-5rem {
  margin-right: 1.5rem;
}

.v-margin-right-05rem {
  margin-right: 0.5rem;
}

.v-margin-left-3rem {
  margin-left: 3rem;
}

.v-margin-left-2rem {
  margin-left: 2rem;
}

.v-margin-left-1rem {
  margin-left: 1rem;
}

.v-margin-left-1-5rem {
  margin-left: 1.5rem;
}

.v-margin-left-05rem {
  margin-left: 0.5rem;
}

.v-margin-_-2rem {
  margin-left: 2rem;
  margin-right: 2rem;
}

.v-padding-1 {
  padding: 1rem;
}

.v-padding-2 {
  padding: 2rem;
}

.v-padding-3 {
  padding: 3rem;
}

.v-padding-_-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.v-padding-left-5 {
  margin-left: 0.75rem;
}

.v-padding-right-1 {
  margin-right: 1rem;
}

.v-padding-right-2 {
  margin-right: 2rem;
}

.v-padding-right-3 {
  margin-right: 3rem;
}

.v-padding-right-5 {
  margin-right: 0.75rem;
}

.v-padding-left-1 {
  margin-left: 1rem;
}

.v-padding-left-2 {
  margin-left: 2rem;
}

.v-padding-left-3 {
  margin-left: 3rem;
}

.v-padding-left-5 {
  margin-left: 0.75rem;
}

.v-no-padding {
  padding: 0;
}

.v-clickable {
  cursor: pointer;
}

.v-half-width {
  width: 50%;
}

.v-full-width {
  width: 100%;
}

.v-margin-bottom-1 {
  margin-bottom: 1rem;
}

.v-margin-bottom-2 {
  margin-bottom: 2rem;
}

.v-margin-top-0 {
  margin-top: 0;
}

.v-margin-top-1 {
  margin-top: 1rem;
}

.v-margin-top-2 {
  margin-top: 2rem;
}

.v-margin-bottom-3 {
  margin-bottom: 3rem;
}

.v-padding-top-1 {
  padding-top: 1rem;
}

.v-padding-top-2 {
  padding-top: 2rem;
}

.v-padding-top-3 {
  padding-top: 3rem;
}

.v-padding-bottom-1 {
  padding-bottom: 1rem;
}

.v-padding-bottom-2 {
  padding-bottom: 2rem;
}

.v-padding-bottom-3 {
  padding-bottom: 3rem;
}

.v-login-page {
  height: 100vh;
  color: #ffffff;
  background: #000000;
  flex-direction: column;
}

.v-login-card {
  position: relative;
  padding: 3rem 5rem;
  border-radius: 1rem;
  width: 50rem;
  background: #000000;
  justify-content: flex-start;
  text-align: left;
  border-radius: 0.5rem;
}

.v-login-title {
  color: #ffffff;
  background: #000000;
  padding: 0 1rem;
  margin-bottom: -1rem;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  z-index: 1;
}

.v-stripe-holder {
  padding: 1rem;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background: #f2f2f2;
}

.v-stripe-comfort-message {
  margin: 1.5rem 0;
}

.v-stripe-highlight {
  display: inline-block;
  padding: 0 0.5rem;
  background: #6772e5;
  border-radius: 4px;
  color: #ffffff;
}

.v-plaid-button {
  border: 0 !important;
  padding: 0 !important;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
}

.v-refund-table {
  width: 100%;
  margin: 0.5rem 0;
}

.v-refund-table tr td {
  padding: 0.2rem 0.5rem;
}

.v-refund-table tr td:last-child {
  text-align: center;
}

.v-refund-table tr th:last-child {
  text-align: center;
}

.v-refund-table thead th {
  padding: 0.2rem 0.5rem;
  background: #f3f3f3;
  border-bottom: 1px solid #eeeeee;
}

.manifest-form {
  align-items: center;
}

.manifest-form input {
  margin-bottom: 0;
  padding-left: 1rem;
}

.manifest-form .v-button {
  margin-left: 1rem;
}

.manifest-subtitle {
  margin-left: 1rem;
  color: #999;
}

.v-table-buy-labels .v-table-td.rates {
  overflow: visible;
}

.v-table-buy-labels .shipment-with-rates .v-table-td.rates .v-ellipsed {
  overflow: visible;
}

.v-table-buy-labels .v-select {
  height: 1rem;
  margin-bottom: 1rem;
}

.v-table-buy-labels .v-select-caption {
  padding-top: 0;
  padding-bottom: 0;
}

.v-table-buy-labels .v-select .v-arrow-icon {
  margin-left: 10px;
}

.v-table-buy-labels .date {
  max-width: 15rem;
}

.v-table-buy-labels .order-id {
  max-width: 10rem;
}

.v-table-buy-labels .rates {
  max-width: 30rem;
}

.v-table-buy-labels .actions {
  max-width: 10rem;
}

.v-table-buy-labels .shipment-loading {
  opacity: 0.5;
}

.v-table-buy-labels .shipment-purchased {
  opacity: 0.5;
}

.v-welcome-text {
  opacity: 0.15;
  color: #000000;
  font-size: 5rem;
  font-weight: 600;
  position: relative;
}

.v-welcome-logo {
  opacity: 0.15;
  font-size: 25rem;
  font-weight: 700;
  line-height: 0.75;
}

.v-welcome-username {
  position: absolute;
  opacity: 0.7;
  top: 0;
  right: 0;
  padding: 2rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.v-iframe-container {
  background: white;
  overflow: auto;
}

.v-iframe-window {
  max-width: 60vw;
  min-height: 68vh;
  border: 0;
}



