.v-no-margin {
  margin: 0;
}

.v-no-padding {
  padding: 0;
}

.v-width-10 {
  width: 10rem;
}

.v-width-15 {
  width: 15rem;
}

.v-width-20 {
  width: 20rem;
}

.v-width-225 {
  width: 22.5rem !important
}

.v-width-25 {
  width: 25rem;
}

.v-width-30 {
  width: 30rem;
}

.v-width-40 {
  width: 40rem;
}

.v-width-50 {
  width: 50rem;
}

.v-width-60 {
  width: 60rem;
}

.v-margin-right-3rem {
  margin-right: 3rem;
}

.v-margin-right-2rem {
  margin-right: 2rem;
}

.v-margin-right-1rem {
  margin-right: 1rem;
}

.v-margin-right-1-5rem {
  margin-right: 1.5rem;
}

.v-margin-right-05rem {
  margin-right: 0.5rem;
}

.v-margin-left-3rem {
  margin-left: 3rem;
}

.v-margin-left-2rem {
  margin-left: 2rem;
}

.v-margin-left-1rem {
  margin-left: 1rem;
}

.v-margin-left-1-5rem {
  margin-left: 1.5rem;
}

.v-margin-left-05rem {
  margin-left: 0.5rem;
}

.v-margin-_-2rem {
  margin-left: 2rem;
  margin-right: 2rem;
}

.v-padding-1 {
  padding: 1rem;
}

.v-padding-2 {
  padding: 2rem;
}

.v-padding-3 {
  padding: 3rem;
}

.v-padding-_-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.v-padding-left-5 {
  margin-left: 0.75rem;
}

.v-padding-right-1 {
  margin-right: 1rem;
}

.v-padding-right-2 {
  margin-right: 2rem;
}

.v-padding-right-3 {
  margin-right: 3rem;
}

.v-padding-right-5 {
  margin-right: 0.75rem;
}

.v-padding-left-1 {
  margin-left: 1rem;
}

.v-padding-left-2 {
  margin-left: 2rem;
}

.v-padding-left-3 {
  margin-left: 3rem;
}

.v-padding-left-5 {
  margin-left: 0.75rem;
}

.v-no-padding {
  padding: 0;
}

.v-clickable {
  cursor: pointer;
}

.v-half-width {
  width: 50%;
}

.v-full-width {
  width: 100%;
}

.v-margin-bottom-1 {
  margin-bottom: 1rem;
}

.v-margin-bottom-2 {
  margin-bottom: 2rem;
}

.v-margin-top-0 {
  margin-top: 0;
}

.v-margin-top-1 {
  margin-top: 1rem;
}

.v-margin-top-2 {
  margin-top: 2rem;
}

.v-margin-bottom-3 {
  margin-bottom: 3rem;
}

.v-padding-top-1 {
  padding-top: 1rem;
}

.v-padding-top-2 {
  padding-top: 2rem;
}

.v-padding-top-3 {
  padding-top: 3rem;
}

.v-padding-bottom-1 {
  padding-bottom: 1rem;
}

.v-padding-bottom-2 {
  padding-bottom: 2rem;
}

.v-padding-bottom-3 {
  padding-bottom: 3rem;
}
