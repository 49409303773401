.v-select {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  /*height: 3rem;*/
}

.v-select-caption {
  justify-content: space-between;
  padding: 1rem 1.25rem;
  box-shadow: 0 0 1px 1px #999999;
  border-radius: 4px;
  background: #fafafa;
  transition: var(--transition);
}

.v-select.v-select-reverse > .v-select-caption {
  background: #000;
}

.v-select.v-select-reverse.v-open .v-select-caption {
  color: #000000;
  background: #ffffff;
  box-shadow: 0 0 1px 1px #000000;
}

.v-select-caption > .v-arrow-icon {
  opacity: 0.5;
  transform: rotate(90deg);
}

.v-select-caption:hover,
.v-select.v-open .v-select-caption {
  color: #ffffff;
  background: #000000;
  box-shadow: 0 0 1px 1px #000000;
}

.v-select.v-open > .v-select-caption > .v-arrow-icon {
  opacity: 1;
  transform: rotate(-90deg);
}

.v-select-list-container {
  position: relative;
  margin-top: 2px;
}

.v-select-list {
  position: absolute;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  /* margin-top: calc(6rem); */
  box-shadow: 0 0 1px 1px #999999;
  border-radius: 4px;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  /* transition: var(--transition-fast); */
}

.v-select-list.v-select-popup {
  bottom: 32px;
}

.v-select.v-open .v-select-list {
  visibility: visible;
  opacity: 1;
}

.v-select-option {
  padding: 0 1rem;
  line-height: 40px;
  display: flex;
  align-items: center;
  background: #ffffff;
  white-space: nowrap;
  transition: var(--transition);
}

.v-select-option:hover {
  background: #f3f3f3;
}

.v-select .v-select-option.v-selected {
  color: #000000;
  background: #dddddd;
}

.v-select-disabled,
.v-select-disabled:hover,
.v-select-disabled > .v-arrow-icon,
.v-select-disabled .v-select-caption {
  color: #999999;
  cursor: not-allowed;
}

.v-select-underlined > .v-select-caption {
  box-shadow: none;
  border: 0;
  border-bottom: 2px solid #aaaaaa;
  border-radius: 0;
  margin-bottom: -0.2rem;
  background: #ffffff;
}

.v-select-underlined:hover > .v-select-caption,
.v-select-underlined.v-open .v-select-caption {
  color: inherit;
  box-shadow: none;
  background: #f3f3f3;
  border-radius: 4px 4px 0 0;
  border-bottom: 2px solid #333333;
}

.v-select-disabled .v-select-caption,
.v-select-disabled:hover .v-select-caption {
  background: #ffffff !important;
  border: 2px solid #f3f3f3 !important;
}

.v-select-wrapper {
  display: flex;
}
.v-select-wrapper.v-select-inline {
  position: relative;
}

.v-select-tiny-no-border > .v-select-caption {
  box-shadow: 0 0 0 1px #eeeeee;
  padding: 0.25rem 1.25rem;
  flex: 1;
  border-radius: 0;
}

.v-select-tiny-no-border .v-select-option {
  line-height: 2em;
  color: #000000;
}

.v-select-small-no-border > .v-select-caption {
  box-shadow: 0 0 0 1px #eeeeee;
  padding: 0.75rem 1.25rem;
  flex: 1;
}

.v-select-small-no-border:hover > .v-select-caption {
  box-shadow: 0 0 0 1px #eeeeee;
}

.v-select-small-no-border > .v-select-list-container {
  margin-top: 2px;
  width: 100%;
  flex: 1;
}

.v-select-inline > .v-select-caption {
  width: 100%;
}

.v-select-inline {
  align-items: center;
}

.v-text-label-left {
  margin-right: 0.75rem;
  font-size: 1.2rem;
  /* font-weight: 600; */
  color: #777777;
  text-align: left;
  flex-shrink: 0;
}
.v-select-wrapper.v-select-inline .v-text-label-left {
  position: absolute;
  top: -1.5rem;
  z-index: 1;
}

.v-select-up .v-select-list {
  /* bottom: calc(3.5rem + 1px); */
  bottom: 3.5rem;
}
.v-add-payment-method {
  margin-top: 0px;
  margin-bottom: 0px;
}
