.v-analytics-card {
  padding-top: 2rem;
  margin-right: 2rem;
  text-align: center;
}

.v-analytics-card .v-card-body {
  padding: 1rem;
}

.v-analytics-label {
  color: #777777;
  background: #ffffff;
  padding: 0 0.75rem;
}

.v-analytics-value {
  font-size: 3.5rem;
  font-weight: 600;
  margin: 1rem 0;
}

.v-analytics-lines {
  margin-bottom: -1rem;
  height: 0.4rem;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.v-empty-state-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
}

.v-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 400px;
  padding: 2rem;
}

.v-empty-state-icon {
  margin-bottom: 1.5rem;
}

.v-empty-state-title {
  margin: 0 0 0.5rem 0;
  color: #333;
}

.v-empty-state-message {
  margin: 0;
  color: #666;
  font-size: 1rem;
}
