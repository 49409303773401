.v-modal-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.85);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: none;
}

.v-modal-visible {
  opacity: 1;
  visibility: visible;
  transition: var(--transition);
}

.v-modal {
  border-radius: 4px;
  min-width: 30rem;
  overflow: hidden;
}

.v-modal-title {
  color: #000000;
  background: #f7f7f7;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 1.5rem 2rem;
  text-transform: uppercase;
  border-bottom: 1px solid #bbbbbb;
  justify-content: space-between;
  cursor: default;
  display: flex;
  align-items: center;
}

.v-modal-body {
  max-height: 80vh;
  background: #ffffff;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  overflow: auto;
  width: 100%;
  padding: 2rem 2rem;
}
.v-modal-body .v-select-list {
  position: fixed;
  width: auto;
}

.v-modal-icon {
  opacity: 0.8;
  cursor: pointer;
  transition: var(--transition);
}

.v-modal-icon:hover {
  opacity: 1;
}

.v-modal-footer {
  padding-top: 1rem;
}

.v-body-content {
  max-height: 60vh;
  overflow: auto;
  width: 100%;
  padding: 2rem 3rem;
}

.v-error-message {
  color: #ff3300;
  font-weight: 500;
}

.v-error-message .v-button {
  color: #ffffff;
  background: #f8665b;
  box-shadow: none;
  padding: 1.75rem 0;
}

.v-modal-heading {
  font-size: 2rem;
  color: #777;
  margin: 0.6em 0;
}
