.v-iframe-container {
  background: white;
  overflow: auto;
}

.v-iframe-window {
  max-width: 60vw;
  min-height: 68vh;
  border: 0;
}
